<template>
  <li class="cursor-pointer py-4 border-solid border-gray-100">
    <div class="flex space-x-3">
      <div class="lg:relative">
        <img
          @click="openModal"
          class="h-8 w-8 rounded-full self-center"
          :src="messageInfo.photo"
          :alt="messageInfo.name"
        />
        <span
          v-if="messageInfo.favourite === 1"
          class="badge badge-danger badge-pill favorite_icon"
        >
          <StarIcon />
        </span>
      </div>
      <div class="flex-1 space-y-1">
        <div class="flex items-center justify-between">
          <div @click="openModal">
            <h3
              class="text-lg text_username flex"
              :class="{
                'font-bold':
                  messageInfo.unread === '1' &&
                  !read_message &&
                  messageType !== 'Sent',
              }"
            >
              <StarIcon
                v-if="
                  messageInfo.unread === '1' &&
                  !read_message &&
                  messageType !== 'Sent'
                "
                style="color: #f1df43"
                class="self-center w-6 h-6 pr-1 text-gray-200 fill-current"
              /><span>{{ messageInfo.username }}</span>
            </h3>
            <p
              class="self-center text-sm text-main-light"
              :class="{
                'font-semibold':
                  messageInfo.unread === '1' &&
                  !read_message &&
                  messageType !== 'Sent',
              }"
            >
              {{ messageInfo.subject }}
            </p>
          </div>
          <div class="relative flex ml-auto">
            <p
              class="
                text-sm text-gray-500
                mr-3
                sm:right-18
                lg:w-40
                w-10
                text-right
                self-center
              "
            >
              {{ messageInfo.ago }}
            </p>
            <button
              @click="openModal"
              type="button"
              class="text-gray-600 hover:text-emerald self-center"
            >
              <ReplyIcon
                class="
                  p-2
                  rounded-full
                  border border-solid border-gray-300
                  w-10
                "
              >
              </ReplyIcon>
            </button>
          </div>
          <div class="flex ml-1">
            <div class="float-right flex">
              <button
                @click="removeMessage(messageInfo.id)"
                type="button"
                class="text-gray-600 hover:text-emerald"
              >
                <XIcon
                  style="color: #ea8d8d"
                  class="p-1 rounded-full border cursor-pointer w-8"
                >
                </XIcon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import { ReplyIcon, XIcon } from "@heroicons/vue/solid";
import { StarIcon } from "@heroicons/vue/solid";
import { APIdelMessage } from "@/api/common/api";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    ReplyIcon,
    StarIcon,
    XIcon,
  },
  props: {
    messageInfo: {
      id: Number,
      userId: Number,
      photo: String,
      subject: String,
      message: String,
      timeElapsed: String,
      folder: String,
    },
    messageType: String,
  },
  data: () => ({
    message_content: "",
    read_message: false,
  }),
  computed: {
    ...mapGetters({}),
  },
  methods: {
    ...mapActions({
      fetchUserReliability: "candidate/fetchUserReliability",
      fetchMessageThread: "candidate/fetchMessageThread",
      fetchtSetModalConfigs: "candidate/fetchtSetModalConfigs",
      fetchtSetMessageModalConfigs: "candidate/fetchtSetMessageModalConfigs",
    }),
    removeMessage(id) {
      this.$swal({
        title: "Remove this message",
        text: "Are you sure you want to delete this message?",
        // icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: "#049A59",
        cancelButtonColor: "rgba(255,0,0,0.7)",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          APIdelMessage(id).then((res) => {
            console.log(res, "res");
            if (res.data.success) {
              this.$swal({
                title: "Successfully removed",
                icon: "success",
                confirmButtonText:
                  '<i class="fa fa-close"></i> Close this window',
                customClass: {
                  confirmButton: "SuccessClosebutton",
                },
              });
              this.$emit("refreshMessage");
            } else {
              this.$swal({
                title: "Oops!",
                icon: "error",
                text: res.data.message,
                confirmButtonText: "Try again",
                customClass: {
                  confirmButton: "ErrorClosebutton",
                },
              });
            }
          });
        }
      });
    },
    openModal() {
      this.fetchUserReliability({ id: 1 });
      this.fetchMessageThread(this.messageInfo.id);
      const message_data = {
        photo: this.messageInfo.photo,
        reply_id: this.messageInfo.message_id,
        user_id:
          this.messageInfo.folder === "sent"
            ? this.messageInfo.recipient_id
            : this.messageInfo.user_id,
        subject: this.messageInfo.subject,
        user_name: this.messageInfo.username,
        type: this.messageInfo.folder,
      };
      const data = {
        isOpen: true,
        iconName: "UserPhoto",
        componentName: "NetworkModalContent",
        title: "",
        data: { modal_type: "base" },
      };
      this.fetchtSetModalConfigs(data);
      this.fetchtSetMessageModalConfigs(message_data);
      this.read_message = true;
    },
  },
};
</script>
